import * as api from '@/api';
import { get } from 'lodash';
import { NotificationManager } from 'react-notifications';

export const GET_SYSTEMS_REQUEST = 'systems/GET_SYSTEMS_REQUEST';
export const GET_NEXT_SYSTEMS_REQUEST = 'systems/GET_NEXT_SYSTEMS_REQUEST';
export const GET_SYSTEMS_RESPONSE = 'systems/GET_SYSTEMS_RESPONSE';
export const GET_NEXT_SYSTEMS_RESPONSE = 'systems/GET_NEXT_SYSTEMS_RESPONSE';
export const GET_SYSTEMS_GEOPOSITION_REQUEST = 'systems/GET_SYSTEMS_GEOPOSITION_REQUEST';
export const GET_SYSTEMS_GEOPOSITION_RESPONSE = 'systems/GET_SYSTEMS_GEOPOSITION_RESPONSE';

// groups
export const GET_TOP_LEVEL_GROUPS_REQUEST = 'systems/GET_TOP_LEVEL_GROUPS_REQUEST';
export const GET_TOP_LEVEL_GROUPS_RESPONSE = 'systems/GET_TOP_LEVEL_GROUPS_RESPONSE';
export const GET_SUB_GROUPS_AND_SYSTEMS_REQUEST = 'systems/GET_SUB_GROUPS_AND_SYSTEMS_REQUEST';
export const GET_SUB_GROUPS_AND_SYSTEMS_RESPONSE = 'systems/GET_SUB_GROUPS_AND_SYSTEMS_RESPONSE';
export const GET_SUB_GROUPS_AND_SYSTEMS_ERROR = 'systems/GET_SUB_GROUPS_AND_SYSTEMS_ERROR';
export const SET_GROUPS_AND_SYSTEMS = 'systems/SET_GROUPS_AND_SYSTEMS';
export const DELETE_GROUP_REQUEST = 'systems/DELETE_GROUP_REQUEST';
export const DELETE_GROUP_RESPONSE = 'systems/DELETE_GROUP_RESPONSE';
export const DELETE_GROUP_ERROR = 'systems/DELETE_GROUP_ERROR';
export const UPDATE_GROUP_NAME_REQUEST = 'systems/UPDATE_GROUP_NAME_REQUEST';
export const UPDATE_GROUP_NAME_RESPONSE = 'systems/UPDATE_GROUP_NAME_RESPONSE';
export const UPDATE_GROUP_NAME_ERROR = 'systems/UPDATE_GROUP_NAME_ERROR';

export const getProAccountSystems =
  ({ includeStatistics } = {}) =>
  async dispatch => {
    dispatch({ type: GET_SYSTEMS_REQUEST });
    dispatch({ type: GET_TOP_LEVEL_GROUPS_REQUEST });

    try {
      const { data = [] } = await api.getMyProAccountSystems({ includeStatistics });
      const systemEntities = includeStatistics ? data.systems ?? [] : data;
      const systemOperation = includeStatistics ? data.systemOperation : null;
      const systemsStatus = includeStatistics ? data.systemsStatus : null;

      const { systems, groups } = systemEntities?.reduce(
        ({ systems, groups }, entity) =>
          entity?.vertexType !== 'spGroup'
            ? { systems: [...systems, entity], groups }
            : { systems: systems, groups: [...groups, entity] },
        { systems: [], groups: [] },
      );

      const payload = {
        systems,
        systemsTotal: systemEntities.length,
        systemOperation,
        systemsStatus,
      };

      dispatch({ type: GET_SYSTEMS_RESPONSE, payload });
      dispatch({ type: GET_TOP_LEVEL_GROUPS_RESPONSE, topLevelGroups: groups });

      return [...systems, ...groups];
    } catch (error) {
      dispatch({
        type: GET_SYSTEMS_RESPONSE,
        payload: { systems: [], systemsTotal: 0, systemOperation: null, systemsStatus: null },
      });
      dispatch({ type: GET_TOP_LEVEL_GROUPS_RESPONSE, topLevelGroups: [] });
      return;
    }
  };

export const updateGroupName = (id, topLevelGroupId, servicePartnerId, name) => async dispatch => {
  dispatch({ type: UPDATE_GROUP_NAME_REQUEST });
  try {
    await api.updateGroupName(servicePartnerId, topLevelGroupId, id, name);
    dispatch({ type: UPDATE_GROUP_NAME_RESPONSE, id, name });
    return true;
  } catch (error) {
    dispatch({ type: UPDATE_GROUP_NAME_ERROR });
    return false;
  }
};

export const createGroup =
  ({ newGroupName, isTopLevelGroup }) =>
  async () => {
    try {
      const response = await api.createGroup({ newGroupName, isTopLevelGroup });
      return response.data;
    } catch (error) {
      NotificationManager.error('Unexpected error occurred, please contact support.');
      return false;
    }
  };

export const attachChildGroup =
  ({ topLevelGroupId, parentGroupId, childGroupId }) =>
  async () => {
    try {
      const response = await api.attachChildGroup({ topLevelGroupId, parentGroupId, childGroupId });
      return response.data;
    } catch (error) {
      NotificationManager.error('Unexpected error occurred, please contact support.');
      return false;
    }
  };

export const addMultipleChildGroups =
  ({ topLevelGroupId, parentGroupId, childGroups }) =>
  async () => {
    try {
      const response = await api.addMultipleChildGroups({
        topLevelGroupId,
        parentSpGroupId: parentGroupId,
        childGroupIds: childGroups,
      });
      return Boolean(response.data); // TODO: Check
    } catch (error) {
      NotificationManager.error('Unexpected error occurred, please contact support.');
      return false;
    }
  };

export const deleteGroup = group => async dispatch => {
  const { id: groupId } = group;
  const parentGroupId = get(group, 'parentGroups.0.parentGroupId', '');

  dispatch({ type: DELETE_GROUP_REQUEST });
  try {
    if (!group.parentGroups || group.parentGroups.length == 0) {
      await api.deleteTopLevelGroup(groupId);
    } else {
      await api.deleteGroup(groupId, parentGroupId);
    }
    dispatch({ type: DELETE_GROUP_RESPONSE, groupId });
    return true;
  } catch (error) {
    dispatch({ type: DELETE_GROUP_ERROR });
    return false;
  }
};

export const deleteSpGroup = group => async dispatch => {
  const { id: groupId } = group;
  const parentGroupId = get(group, 'parentGroups.0.parentGroupId', ''); // TODO: Refactor

  dispatch({ type: DELETE_GROUP_REQUEST });
  try {
    if (!group.parentGroups || group.parentGroups.length == 0) {
      await api.deleteTopLevelGroup(groupId);
    } else {
      await api.deleteSpGroup(groupId, parentGroupId);
    }
    dispatch({ type: DELETE_GROUP_RESPONSE, groupId });
    return true;
  } catch (error) {
    dispatch({ type: DELETE_GROUP_ERROR });
    return false;
  }
};

export const setSettingsToGroups = (menuId, settingsToGroups) => async () => {
  await api.setSettingsToGroups(menuId, settingsToGroups);
};

export const setSchedulingToGroup = (systemId, groups) => async () => {
  await api.setSchedulingToGroup(systemId, groups);
};

export const setGroupsAndSystems =
  (groupsAndSystems, groupId = false) =>
  async dispatch =>
    dispatch({ type: SET_GROUPS_AND_SYSTEMS, groupsAndSystems, groupId });

export const getSubGroupsAndSystemsById =
  ({ groupId, topLevelGroupId, servicePartnerId }) =>
  async dispatch => {
    dispatch({ type: GET_SUB_GROUPS_AND_SYSTEMS_REQUEST });
    try {
      const response = await api.getSubGroupsById({ groupId, topLevelGroupId, servicePartnerId });
      const { childGroups = [] } = response.data;
      dispatch({ type: GET_SUB_GROUPS_AND_SYSTEMS_RESPONSE });
      dispatch(setGroupsAndSystems(childGroups, groupId));
    } catch (error) {
      dispatch({ type: GET_SUB_GROUPS_AND_SYSTEMS_ERROR });
    }
  };
