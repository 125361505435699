import { SubscriptionOrderStatus } from '@/pages/Store/types';

import { BRAND_APPROVAL_STATUSES } from '@/config/common';

import { menuItemIds } from './constants';

export const isMenuLinkDisabled = ({ menuItem, userPermissions = {}, brands = [] }) => {
  const { spPayments = {} } = userPermissions;

  let hasSubscription =
    (spPayments.isValid && !spPayments.isPaused) || spPayments.subscription?.status === SubscriptionOrderStatus.Trial;

  if (menuItem.id === menuItemIds.RedeemVouchers) {
    hasSubscription = Boolean(spPayments.subscription);
  }

  const hasUnapprovedBrands = brands?.some(brand => brand.approvalStatus !== BRAND_APPROVAL_STATUSES.APPROVED);

  return {
    disabledDueToBrands: menuItem.brandDepend && (hasUnapprovedBrands || brands.length === 0),
    disabledDueToPremium: !hasSubscription && menuItem.subscriptionDepend,
  };
};
