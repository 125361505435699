import React, { ReactElement, useEffect } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

function UnAuthGuard({ children, userInfo }): ReactElement {
  const { push } = useHistory();
  const location = useLocation();
  const noRedirectPaths = ['/delete/confirmation'];

  useEffect(() => {
    if (userInfo && !noRedirectPaths.includes(location.pathname)) return push('/systems');
  }, [userInfo, location.pathname]);

  return <>{children}</>;
}

export default UnAuthGuard;
