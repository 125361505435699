import { combineReducers } from 'redux';

import confirmEmailUpdate from '@/pages/ConfirmEmailUpdate/reducers';
import editServicePartner from '@/pages/EditServicePartner/reducers';
import faq from '@/pages/Faq/reducers';
import forgotPassword from '@/pages/ForgotPassword/reducers';
import invitations from '@/pages/Invitations/reducers';
import login from '@/pages/Login/reducers';
import addressTab from '@/pages/ProfilePage/components/AddressTab/reducers';
import notificationSettingsTab from '@/pages/ProfilePage/components/NotificationSettingsTab/reducers';
import profileTab from '@/pages/ProfilePage/components/ProfileTab/reducers';
import profilePage from '@/pages/ProfilePage/reducers';
import register from '@/pages/Register/reducers';
import reports from '@/pages/Reports/reducer';
import security from '@/pages/Security/reducers';
import smartguide from '@/pages/SmartGuide/reducers';
import aboutManufacturerTab from '@/pages/SystemSettings/components/AboutManufacturerTab/reducers';
import deviceScheduling from '@/pages/SystemSettings/components/DeviceSchedulingTab/reducers';
import systemDiagramTab from '@/pages/SystemSettings/components/SystemDiagramTab/reducers';
import systemHistoryTab from '@/pages/SystemSettings/components/SystemHistoryTab/reducers';
import deviceMenu from '@/pages/SystemSettings/components/SystemMenuTab/DeviceMenu/reducers';
import systemNotificationsTab from '@/pages/SystemSettings/components/SystemNotificationsTab/store/reducers';
import systemStatusTab from '@/pages/SystemSettings/components/SystemStatusTab/reducers';
import systemSettings from '@/pages/SystemSettings/reducers';
import systems from '@/pages/Systems/reducers';
import firmwares from '@/pages/UpdateDeviceFirmware/reducers';
import app from '../components/AppInitializer/reducers';
import features from '../components/FeaturesFlags/reducers';
import notifications from '../components/layout/Header/reducers';
import language from '../components/layout/LanguageSelector/reducers';
import shop from '../pages/Store/Shop/store/reducers';
import textDb from '../text-db/reducers';

export const createRootReducers = (injectedReducers = {}) => {
  return combineReducers({
    app,
    register,
    login,
    forgotPassword,
    language,
    smartguide,
    textDb,
    notifications,
    profilePage,
    profileTab,
    addressTab,
    systems,
    reports,
    invitations,
    deviceMenu,
    shop,
    features,
    security,
    editServicePartner,
    firmwares,
    confirmEmailUpdate,
    systemSettings,
    systemStatusTab,
    aboutManufacturerTab,
    systemNotificationsTab,
    systemHistoryTab,
    systemDiagramTab,
    notificationSettingsTab,
    deviceScheduling,
    faq,
    ...injectedReducers,
  });
};
