// @ts-check
import { getAuthTokens, isAzureB2CAuth } from '@/utils/localStorage/authentication';
import axios from 'axios';
import qs from 'querystring';

export * from './points';

export const MY_UPLINK_CLIENT_ID = 'My-Uplink-Pro-Web';

export const API_URL = process.env.API_URL || '';
export const WEB_URL = process.env.WEB_URL || '';
export const WEB_PRO_URL = process.env.WEB_PRO_URL || '';
export const WEB_DEV_URL = process.env.WEB_DEV_URL || '';
export const ENV = process.env.ENV || '';

const CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const CONFIG_FORM = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json',
  },
};

const sortByPrio = parameters => {
  const sortedParameters = [...parameters];
  sortedParameters.sort((paramA, paramB) => {
    if (paramA.prio && !paramB.prio) {
      return -1;
    }

    if (!paramA.prio && paramB.prio) {
      return 1;
    }

    if (paramA.prio && paramB.prio) {
      if (+paramA.prio < +paramB.prio) {
        return -1;
      }
      if (+paramA.prio > +paramB.prio) {
        return 1;
      }
    }
  });
  return sortedParameters;
};

export const issueAuthToken = ({ username, password, clientId = MY_UPLINK_CLIENT_ID, options = {} }) => {
  const body = qs.stringify({
    grant_type: 'password',
    username: username,
    password: password,
    client_id: clientId,
    ...options,
  });
  return axios.post(`${API_URL}/oauth/token`, body, CONFIG_FORM);
};

export const issueAuthTokenProAccount = ({
  username,
  password,
  spId,
  clientId = MY_UPLINK_CLIENT_ID,
  options = {},
}) => {
  const body = qs.stringify({
    grant_type: 'password',
    username,
    password,
    client_id: clientId,
    spId,
    ...options,
  });

  return axios.post(`${API_URL}/oauth/token`, body, CONFIG_FORM);
};

export const validateUserCredentials = (username, password) => {
  const body = {
    grant_type: 'password',
    email: username,
    password: password,
  };
  return axios.post(`${API_URL}/v2/users/validate`, body, CONFIG);
};

export const validateOldUserCredentials = (username, password) => {
  const body = {
    email: username,
    password: password,
  };
  return axios.post(`${API_URL}/v2/old-users/validate`, body, CONFIG);
};

export const migrateOldUser = (oldUserId, acceptedTosVersion, acceptedPrivacyPolicyVersion, newPassword) => {
  const body = {
    acceptedAgreement: acceptedTosVersion,
    acceptedPrivacyPolicy: acceptedPrivacyPolicyVersion,
    newPassword: newPassword,
  };

  return axios.put(`${API_URL}/v2/old-users/migrate?oldUserId=${oldUserId}`, body, CONFIG);
};

export const setUpAgreements = (username, password, acceptedTosVersion, acceptedPrivacyPolicyVersion) => {
  const body = qs.stringify({
    grant_type: 'password',
    email: username,
    password: password,
    acceptedAgreement: acceptedTosVersion,
    acceptedPrivacyPolicy: acceptedPrivacyPolicyVersion,
  });
  return axios.put(`${API_URL}/v2/users/updateAgreements`, body, CONFIG_FORM);
};

export const getSmartHomeMode = groupId => {
  return axios.get(`${API_URL}/v2/groups/${groupId}/smart-home-mode`, CONFIG);
};

export const setSmartHomeMode = (groupId, smartHomeMode) => {
  const body = {
    smartHomeMode: smartHomeMode,
  };
  return axios.put(`${API_URL}/v2/groups/${groupId}/smart-home-mode`, body, CONFIG);
};

export const issueAnonymousAuthToken = () =>
  issueAuthToken({ username: 'anonymous', password: 'password', clientId: 'Anonymous-Web-Client' });

export const reissueAuthToken = (refreshToken, spId) => {
  const body = `grant_type=refresh_token&refresh_token=${refreshToken}&client_id=${MY_UPLINK_CLIENT_ID}&spId=${spId}`;

  return axios.post(`${API_URL}/oauth/token`, body, CONFIG_FORM);
};

export const sendRecoveryLink = email => {
  const body = { email };
  return axios.post(`${API_URL}/v2/users/recovery-link/send`, body, CONFIG);
};

export const verifyRecoveryToken = token => {
  const body = { token };
  return axios.post(`${API_URL}/v2/users/recovery-link/verify`, body, CONFIG);
};

export const resetPassword = (token, password) => {
  const body = { token, password };
  return axios.post(`${API_URL}/v2/users/recovery-link/reset-password`, body, CONFIG);
};

export const authorizeUserUnderProAccount = proAccountId => {
  return axios.post(`${API_URL}/v2/auth/service-partners/${proAccountId}`, CONFIG_FORM);
};

export const getProAccountIds = token => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  };
  return axios.get(`${API_URL}/v2/service-partners/me`, isAzureB2CAuth() ? CONFIG : config);
};

export const getActiveProAccountIds = token => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  };
  return axios.get(`${API_URL}/v2/service-partners/me/active`, isAzureB2CAuth() ? CONFIG : config);
};

export const createUser = (anonymousToken, user) => {
  return axios.post(`${API_URL}/v2/users`, user, {
    ...CONFIG,
    headers: {
      ...CONFIG.headers,
      Authorization: `Bearer ${anonymousToken}`,
    },
  });
};

export const getUserInfo = () => {
  return axios.get(`${API_URL}/v2/users/me`);
};

export const getAddressFromId = addressId => {
  return axios.get(`${API_URL}/v2/addresses/${addressId}`);
};

export const getUserSystems = () => {
  return axios.get(`${API_URL}/v2/groups/me`);
};

export const getGroupFullInfo = groupId => {
  return axios.get(`${API_URL}/v3/groups/${groupId}`);
};

export const getStatus = () => {
  /*
 TODO: implement when the support from backend be implemented
 */
  return new Promise(resolve => {
    const statuses = ['Working fine', 'Has alarm', 'Is offline'];
    const randomNumber = Math.round(Math.random() * statuses.length);
    const response = {
      status: 200,
      data: {
        status: statuses[randomNumber],
      },
    };
    resolve(response);
  });
};

export const getAlarms = ({ deviceId, type = 'all', page = 1, pageSize = 10 }) =>
  axios.get(`${API_URL}/v2/devices/${deviceId}/alerts/paged/${type}`, {
    params: {
      page,
      pageSize,
    },
  });

export const getArchivedAlarms = ({ deviceId, page = 1, pageSize = 10 }) =>
  axios.get(`${API_URL}/v2/devices/${deviceId}/alerts/archived/paged`, {
    params: {
      page,
      pageSize,
    },
  });

export const getAlarmCauses = (deviceId, alertId) =>
  axios.get(`${API_URL}/v2/devices/${deviceId}/alerts/${alertId}/troubleshooting-guides`);

export const hasActiveAlarm = groupId => {
  return axios.get(`${API_URL}/v2/groups/${groupId}/alerts/active/any`);
};

export const getActiveAidMode = deviceId => {
  return axios.get(`${API_URL}/v2/devices/${deviceId}/aidMode`);
};

export const resetAlarm = alarm => {
  const timeout = 10;
  return axios.post(`${API_URL}/v2/devices/${alarm.deviceId}/resetAlarm/${alarm.alarmId}/${timeout}`);
};

export const activateAidModeForAlarm = (alarm, aidMode) => {
  const timeout = 10;
  return axios.post(`${API_URL}/v2/devices/${alarm.deviceId}/invoke/setAidMode/${aidMode}/${timeout}`);
};

export const getDevices = async systemId => {
  return await axios.get(`${API_URL}/v2/groups/${systemId}/devices`);
};

export const disconnectDevice = async (deviceId, groupId) => {
  return await axios.delete(`${API_URL}/v2/devices/${deviceId}/${groupId}`);
};

export const disconnectSystem = async (groupId, userId) => {
  return await axios.delete(`${API_URL}/v2/groups/${groupId}/${userId}`);
};

export const getReports = async (type, page = 1, pageSize = 10) => {
  const indexType = type === 'daily' ? 1 : 2; /* remove after backend will be accept weekly/daily instead of index */
  return axios.get(`${API_URL}/v2/service-partner/reports/${indexType}?page=${page}&pageSize=${pageSize}`);
};

export const getReportByParameters = async (type, reportDate) => {
  const indexType = type === 'daily' ? 1 : 2; /* remove after backend will be accept weekly/daily instead of index */
  return axios.get(`${API_URL}/v2/service-partner/report/${indexType}/${reportDate}`);
};

export const getRegisteredProducts = async userId => {
  return await axios.get(`${API_URL}/v2/Registrations/user/${userId}`);
};

export const findProduct = async serialNumber => {
  return await axios.get(`${API_URL}/v2/Registrations/register/${serialNumber}`);
};

export const registerProduct = async (values, userInfo) => {
  const MYUPLINK_SITE = 'MYUPLINK';

  const address = {
    lineOne: values.address1,
    lineTwo: values.address2,
    postalCode: values.postalCode,
    city: values.city,
    region: values.region,
    country: values.country,
  };
  const newAddress = await axios.post(`${API_URL}/v2/addresses/user`, address, CONFIG);
  if (newAddress.status === 200 || newAddress.status === 201) {
    const body = {
      serialNumber: values.serialNumber,
      operatingHours: values.operatingHours,
      installationDate: values.installationDate,
      registrationDate: values.registrationDate,
      warrantyEndDate: values.warrantyEndDate,
      customerName: values.name,
      userId: userInfo.id,
      userAddressId: userInfo.address.id,
      servicePartnerId: '',
      userEmail: values.email,
      systemAddressId: newAddress.data.id,
      installerName: values.installerName,
      phone: values.phone,
      modelName: values.productName,
      site: MYUPLINK_SITE,
    };
    return axios.post(`${API_URL}/v2/Registrations/register`, body, CONFIG);
  }
  return newAddress;
};

export const deviceParameters = deviceId =>
  new Promise(resolve => {
    let parameters = [];
    switch (deviceId) {
      case 'device1':
        parameters = [
          { id: 'power', name: 'Produced Power', unit: 'kW' },
          { id: 'energy', name: 'Produced Energy', unit: 'kWh' },
        ];
        break;
      case 'device2':
        parameters = [
          { id: 'temperature', name: 'Temperature', unit: '°C' },
          { id: 'humidity', name: 'Humidity', unit: '%' },
        ];
        break;
      case 'device3':
        parameters = [
          { id: 'pool_temperature', name: 'Pool Temperature', unit: '°C' },
          { id: 'air_temperature', name: 'Air Temperature', unit: '°C' },
        ];
        break;
      case 'device4':
        parameters = [
          { id: 'temperature', name: 'Temperature', unit: '°C' },
          { id: 'humidity', name: 'Humidity', unit: '%' },
        ];
        break;
    }
    resolve(parameters);
  });

export const getAllParameters = async groupId => {
  const res = await axios.get(`${API_URL}/v2/group/${groupId}/categories/all`);

  const categories = {};
  res.data.parameters.forEach(p => {
    if (categories[p.category]) {
      categories[p.category].push(p);
    } else {
      categories[p.category] = [p];
    }
  });

  return Object.keys(categories).map(c => ({ id: c, name: c, parameters: sortByPrio(categories[c]) }));
};

export const getHistoryParameters = async groupId => {
  const res = await axios.get(`${API_URL}/v2/group/${groupId}/categories/all?chart=history`);

  const categories = {};
  res.data.parameters.forEach(p => {
    if (categories[p.category]) {
      categories[p.category].push(p);
    } else {
      categories[p.category] = [p];
    }
  });

  return Object.keys(categories).map(c => ({ id: c, name: c, parameters: sortByPrio(categories[c]) }));
};

export const getOperatingInformation = async (groupId, deviceId, proAccountId) => {
  return await axios({
    method: 'post',
    url: `${API_URL}/v2/groups/${groupId}/operating-info`,
    data: {
      deviceId: deviceId,
      servicePartnerId: proAccountId,
      chart: '',
      accessLevel: 'All',
    },
  });
};

export const addDevice = async (serialNumber, token, groupName, groupId, address) => {
  return await axios({
    method: 'post',
    url: `${API_URL}/v2/devices/me`,
    data: {
      serialNumber: serialNumber,
      token: token,
      name: groupName,
      groupId: groupId,
      address: address,
    },
  });
};

export const validateToken = async (serialNumber, token) => {
  return await axios({
    method: 'post',
    url: `${API_URL}/v2/devices/validateToken`,
    data: {
      serialNumber: serialNumber,
      token: token,
    },
  });
};

// type ExportType = 'Default' | 'Full';
/**
 * Fetches the history export for a device.
 *
 * @param {Object} params - The parameters for fetching the history export.
 * @param {string} params.deviceId - The ID of the device.
 * @param {'Default' | 'Full'} [params.exportType='Default'] - The type of export. Options: 'Default', 'Full'.
 * @param {number[]} params.parameterIds - Array of parameter IDs to be included in the export.
 * @param {string} params.dateFrom - The start date for the export period.
 * @param {string} params.dateTo - The end date for the export period.
 * @returns {Promise} - The promise object representing the result of the HTTP request.
 */
export const getHistoryExport = ({ deviceId, exportType = 'Default', parameterIds, dateFrom, dateTo }) =>
  axios.post(`${API_URL}/v2/devices/${deviceId}/history/export`, { exportType, parameterIds, dateFrom, dateTo });

export const getDeviceParameterValue = async (deviceId, parameterIds) => {
  // TODO: Following statement should be refactored when API endpoint supports several parameter IDs
  // and validateStatus should be removed when tiles config is taken from the corresponding API.
  const validateStatus = () => true;

  try {
    if (!deviceId || parameterIds.length === 0) {
      return { status: 200, data: null };
    }

    const queryParams = `parameters=${parameterIds.join(',')}`;
    const response = await axios.get(`${API_URL}/v2/devices/${deviceId}/points?${queryParams}`, { validateStatus });

    const data = parameterIds.map(pId => {
      const rd = response.data.find(r => r.parameterId === pId);
      if (rd) {
        return rd;
      }
      return {
        timestamp: '0000-00-00T09:00:00+00:00',
        value: null,
        parameterId: pId,
      };
    });

    return { status: 200, data };
  } catch (error) {
    const data = parameterIds.map(id => ({
      timestamp: '0000-00-00T09:00:00+00:00',
      value: null,
      parameterId: id,
    }));

    return { status: 200, data };
  }
};

export const getUserProfile = id => {
  return axios.get(`${API_URL}/v2/users/${id}/profile`);
};

export const getTimeZones = () => {
  return axios.get(`${API_URL}/v2/timezones`);
};

export const updateUserProfile = (id, profile) => {
  return axios.put(`${API_URL}/v2/users/${id}/profile`, profile, CONFIG);
};

export const updateUserLanguage = (userInfo, language) => {
  const profile = JSON.parse(JSON.stringify(userInfo));
  profile.preferredLanguage = language;
  return axios.put(`${API_URL}/v2/users/${userInfo.id}/profile`, profile, CONFIG);
};

export const changePassword = (id, oldPassword, newPassword) => {
  const body = {
    oldPassword: oldPassword,
    newPassword: newPassword,
  };

  return axios.post(`${API_URL}/v2/users/${id}/password`, body, CONFIG);
};

export const changeEmail = (id, oldEmail, newEmail, password) => {
  const body = {
    userId: id,
    oldEmail: oldEmail,
    newEmail: newEmail,
    password: password,
  };

  return axios.post(`${API_URL}/v2/users/${id}/email`, body, CONFIG);
};

export const getAddressInfo = addressId => {
  return axios.get(`${API_URL}/v2/addresses/${addressId}`);
};

export const addAddressIdToUser = (userId, addressId) => {
  const body = {
    addressId: addressId,
  };

  return axios.patch(`${API_URL}/v2/users/${userId}`, body, CONFIG);
};

export const changeAddressInfo = address => {
  return axios.put(`${API_URL}/v2/addresses/user/${address.id}`, address, CONFIG);
};

export const createAddressInfo = address => {
  return axios.post(`${API_URL}/v2/addresses/user`, address, CONFIG);
};

export const getText = async (ids, langId) => {
  const responses = await Promise.all(ids.map(id => axios.get(`${API_URL}/v2/text/single/${id}/${langId}`)));
  return responses.filter(r => r.status === 200).map(r => r.data);
};

export const patchMenuSetting = async (deviceId, menuId, parameterId, value, unit) => {
  return await axios.put(`${API_URL}/v2/devices/${deviceId}/menu/${menuId}/rawpoints/${parameterId}`, {
    value: `${value}`,
    unit: unit,
  });
};

export const getBrandDetails = async brandId => {
  return await axios.get(`${API_URL}/v2/brands/${brandId}`);
};

export const getAllBrands = async () => {
  return await axios.get(`${API_URL}/v2/brands`);
};

export const createJiraServiceDeskSupportTicket = values => {
  let body = {
    raiseOnBehalfOf: `${values.fromEmail}`,
    requestTypeId: `${values.topic}`,
    platform: `${values.platform}`,
    summary: `${values.summary}`,
    description: `${values.message}`,
  };
  if (values.system) {
    body = { ...body, deviceId: values.system, brandId: values.brandId, description: `${values.message}` };
  }
  return axios.post(`${API_URL}/create-support-ticket`, body, CONFIG);
};

export const findUserByEmail = async (anonymousToken, email) => {
  return await axios.head(`${API_URL}/v2/users/search?email=${encodeURIComponent(email)}`, {
    ...CONFIG,
    headers: {
      ...CONFIG.headers,
      Authorization: `Bearer ${anonymousToken}`,
    },
  });
};

export const checkOldUserEmail = async email => {
  return await axios.head(`${API_URL}/v2/old-users/${email}`);
};

export const getFirmwaresByBrand = async brandId => {
  return await axios.get(`${API_URL}/v3/firmwareAlias/brands/${brandId.toUpperCase()}/all?latest=true`);
};

export const getInvitation = async invitationId => {
  return await axios.get(`${API_URL}/v2/invitations/token/${invitationId}`);
};

export const getProAccount = id => {
  return axios.get(`${API_URL}/v2/servicePartners/${id}`);
};

export const changeSystemName = (spId, groupId, name) => {
  const body = {
    groupId: groupId,
    alias: name,
  };
  return axios.patch(`${API_URL}/v2/servicePartners/groups/${groupId}/alias?servicePartnerId=${spId}`, body, CONFIG);
};

export const acceptInvitation = (invitationId, systemId, userId, proAccountId) => {
  const body = {
    groupIds: [`${systemId}`],
    servicePartnerId: `${proAccountId}`,
    userName: `${userId}`,
  };

  return axios.patch(`${API_URL}/v2/invitations/${invitationId}/accept`, body, CONFIG);
};

export const declineInvitation = invitationId => {
  const body = {};

  return axios.patch(`${API_URL}/v2/invitations/${invitationId}/decline`, body, CONFIG);
};

export const getSystemUsers = async systemId => {
  return await axios.get(`${API_URL}/v2/groups/${systemId}/security/users`);
};

export const connectUser = async (systemId, userEmail, securityLevel) => {
  return await axios.put(`${API_URL}/v2/groups/${systemId}/users/email/${userEmail}?role=${securityLevel}`);
};

export const disconnectUser = async (systemId, userId) => {
  return await axios.delete(`${API_URL}/v2/groups/${systemId}/users/${userId}`);
};

export const disconnectProAccount = async (systemId, proAccountId) => {
  return await axios.delete(`${API_URL}/v2/groups/${systemId}/servicePartner/${proAccountId}`);
};

export const changeUserSecurityLevel = async (systemId, userId, securityLevel) => {
  return await axios.patch(`${API_URL}/v2/groups/${systemId}/users/${userId}?role=${securityLevel}`);
};

export const sendConfirmationDeleteUser = async () => {
  return axios.post(`${API_URL}/v2/users/send-confirmation-delete-user`);
};

export const confirmDeleteUser = async token => {
  return axios.post(`${API_URL}/v2/users/confirm-delete-user`, { token });
};

export const cancelDeleteUser = async token => {
  return axios.post(`${API_URL}/v2/users/delete-confirmation/cancel`, { token });
};

export const validateDeleteUserToken = async token => {
  return axios.post(`${API_URL}/v2/users/delete-confirmation/validate`, { token });
};

export const deleteUserAccount = async (userId, password) => {
  return await axios.delete(`${API_URL}/v2/users/${userId}`, { data: { password } });
};

export const deleteLastProAccount = async proAccountId => {
  return await axios.delete(`${API_URL}/v2/service-partner?id=${proAccountId}`);
};

// TODO: Review with BE if this is safe without an SP ID
export const leaveProAccount = async () => {
  return await axios.delete(`${API_URL}/v2/leave-service-partner`);
};

export const getProAccountUsers = async proAccountId => {
  return await axios.get(`${API_URL}/v2/service-partner-user/${proAccountId}/users`);
};

export const connectUserToProAccount = async (proAccountId, email, role) => {
  const body = {
    organizationId: proAccountId,
    email: email,
    role: role,
  };

  return axios.post(`${API_URL}/v2/service-partner-user/email`, body, CONFIG);
};

export const disconnectUserFromProAccount = async (proAccountId, userId) => {
  return await axios.delete(`${API_URL}/v2/service-partner-user?servicePartnerId=${proAccountId}&userId=${userId}`);
};

export const getProAccountUser = async (proAccountId, userId) => {
  return await axios.get(`${API_URL}/v2/service-partner-user/${proAccountId}/${userId}`);
};

export const updateProAccountRole = async (
  proAccountId,
  userId,
  role,
  emailNotifications,
  pushNotifications,
  regularNotifications,
) => {
  const body = {
    organizationId: proAccountId,
    userId: userId,
    role: role,
    emailNotifications: emailNotifications,
    pushNotifications: pushNotifications,
    regularNotifications: regularNotifications,
  };
  return await axios.put(`${API_URL}/v2/service-partner-user`, body, CONFIG);
};

export const updateProAccountInfo = async (emailNotifications, pushNotifications, regularNotifications) => {
  const body = {
    emailNotifications: emailNotifications,
    pushNotifications: pushNotifications,
    regularNotifications: regularNotifications,
  };
  return await axios.patch(`${API_URL}/v2/service-partner-user/notification-preference`, body, CONFIG);
};

export const confirmEmail = token => {
  const body = { token };
  return axios.post(`${API_URL}/v2/users/confirm-email`, body, CONFIG);
};

export const sendConfirmationLink = userId => {
  const body = { userId };
  return axios.post(`${API_URL}/v2/users/confirmation-link/send`, body, CONFIG);
};

export const confirmEmailUpdate = token => {
  const body = { token };
  return axios.post(`${API_URL}/v2/users/email/confirm-update`, body, CONFIG);
};

export const addInvitation = invitation => {
  // TODO: Investigate why not add for all requests
  const access_token = getAuthTokens();

  const config = {
    headers: {
      'Content-Type': 'application/json-patch+json',
      Accept: 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
  };
  const body = {
    email: `${invitation.email}`,
    type: 0,
    name: `${invitation.name}`,
    numberOfGroups: invitation.numberOfGroups,
    additionalNote: invitation.additionalNote,
  };
  return axios.post(`${API_URL}/v2/invitations`, body, config);
};

export const getProAccountInfo = id => {
  return axios.get(`${API_URL}/v2/service-partner/${id}`);
};

export const editProAccountInfo = sp => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json-patch+json',
    },
  };
  return axios.patch(`${API_URL}/v2/service-partner`, sp, config);
};

// eslint-disable-next-line no-unused-vars
export const addSystem = accountId => {
  return new Promise(resolve => {
    resolve({ status: 200, data: {} });
  });
};

export const getGroup = groupId => {
  return axios.get(`${API_URL}/v2/groups/${groupId}`);
};

export const getProAccountsByGroup = groupId => {
  return axios.get(`${API_URL}/v2/groups/${groupId}/servicePartners`);
};

export const getProAccountInvitations = () => {
  return axios.get(`${API_URL}/v2/invitations/servicePartner/sent`);
};

export const getProAccountGroups = spId => {
  return axios.get(`${API_URL}/v2/servicePartners/${spId}/groups`);
};

export const getMyProAccountSystems = params => {
  return axios.get(`${API_URL}/v3/service-partner-groups/list`, {
    params,
  });
};

export const getMyProAccountSystemsByGroupId = ({ params = { language: 'en' }, groupId }) => {
  return axios.get(`${API_URL}/v2/service-partner-groups/list/${groupId}`, {
    params,
  });
};

export const getMyProAccountAncestorGroupsBySystemId = ({ systemId }) => {
  return axios.get(`${API_URL}/v2/groups/${systemId}/ancestors`, {});
};

export const getProAccountDevices = groupId => {
  return axios.get(`${API_URL}/v2/groups/${groupId}/devices`);
};

export const getProAccountSystemTags = (proAccountId, groupId) => {
  return axios.get(`${API_URL}/v2/servicePartners/${proAccountId}/group/${groupId}/tags`);
};

export const deleteProAccountSystemTag = (proAccountId, groupId, tag) => {
  return axios.delete(`${API_URL}/v2/servicePartners/${proAccountId}/group/${groupId}/tags?tagValues=${tag}`);
};

export const addProAccountSystemTag = (proAccountId, groupId, tag) => {
  const body = [tag];
  return axios.put(`${API_URL}/v2/servicePartners/${proAccountId}/group/${groupId}/tags`, body);
};

export const updateProAccountTags = (proAccountId, groupId, tags = []) => {
  return axios.put(`${API_URL}/v2/servicePartners/${proAccountId}/group/${groupId}/tags`, tags);
};

export const getCustomerInformation = (proAccountId, groupId) => {
  return axios.get(`${API_URL}/v2/servicePartners/${proAccountId}/groups/${groupId}`);
};

export const disconnectProAccountSystem = (proAccountId, system) => {
  return axios.delete(`${API_URL}/v2/servicePartners/${proAccountId}/groups/${system.id}`);
};

export const deleteProAccountInvitation = (invitation, token) => {
  const config = {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.delete(`${API_URL}/v2/invitations/${invitation.id}`, config);
};

export const resendProAccountInvitation = (invitation, token) => {
  const config = {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.patch(`${API_URL}/v2/invitations/${invitation.id}/resend`, config);
};

export const createProAccount = proAccount => axios.post(`${API_URL}/v2/service-partner`, proAccount);

export const getProAccountSubscriptionPaymentStatus = () => axios.get(`${API_URL}/v2/recurring-payments/me/validate`);

export const getDeviceAvailableFeatures = deviceId => {
  return axios.get(`${API_URL}/v2/devices/${deviceId}/availableFeatures`);
};

export const getCountriesList = () => axios.get(`${API_URL}/v2/countries`);

export const getLatestAgreementsVersionsList = () => axios.get(`${API_URL}/v2/agreements/latest`);

export const getCloudStatus = () => axios.get(`${API_URL}/v2/status/pro-web`);

export const getDeviceMenu = (deviceId, id) => {
  return axios(`${API_URL}/v3/devices/${deviceId}/menu/${id}`);
};

export const invokeDeviceEvent = (deviceId, eventId) =>
  axios.post(`${API_URL}/v2/devices/${deviceId}/invoke/triggerEvent/${eventId}`);

export const getIotStoreRedirectUrl = async () => {
  return axios.get(`${API_URL}/v2/e-commerce/service-partner/iotStoreUrl`);
};

export const getSystemFlowDiagramInfo = async deviceId => {
  return axios.get(`${API_URL}/v2/devices/${deviceId}/systemFlow?brandId=CLIMATEMASTER&paramId=160244&mock=false`);
};

export const getAllLanguages = async () => axios.get(`${API_URL}/v2/brands/supported-languages/all`);

export const getSupportedLanguages = async () => axios.get(`${API_URL}/v2/brands/supported-languages/me`);

export const getDeviceUpdateStatus = async deviceId => {
  return axios.get(`${API_URL}/v2/devices/${deviceId}/firmware/status`, CONFIG);
};

export const updateDeviceFirmware = (deviceId, slaveDeviceId = '') => {
  return axios.post(`${API_URL}/v2/devices/${deviceId}/firmware/forceUpdate/${slaveDeviceId}`);
};

export const requestUpdateDeviceFirmware = (deviceId, slaveDeviceId = '') => {
  return axios.post(`${API_URL}/v2/devices/${deviceId}/firmware/requestUpdate/${slaveDeviceId}`);
};

export const getActiveBrands = () => {
  return axios.get(`${API_URL}/v2/firmware/brands/all`);
};

export const getSubGroupsById = async ({ groupId, topLevelGroupId, proAccountId }) => {
  return axios.get(
    `${API_URL}/v2/service-partner-groups/single?groupId=${groupId}&topLevelGroupId=${topLevelGroupId}&servicePartnerId=${proAccountId}`,
  );
};

export const deleteTopLevelGroup = async groupId => {
  return axios.delete(`${API_URL}/v2/service-partner-groups/delete-top-level-group?groupId=${groupId}`);
};

export const deleteGroup = async (groupId, parentGroupId) => {
  return axios.delete(
    `${API_URL}/v2/service-partner-groups/remove-child-group?childGroupId=${groupId}&parentGroupId=${parentGroupId}`,
  );
};

export const deleteSpGroup = async (groupId, parentGroupId) => {
  return axios.delete(
    `${API_URL}/v2/service-partner-groups/delete-spgroup?groupId=${groupId}&parentGroupId=${parentGroupId}`,
  );
};

export const updateSystemForNotifications = async (groupId, notifyByEmail, notifyByPush) => {
  return await axios.patch(`${API_URL}/v2/groups/${groupId}/service-partner/notification-preference`, {
    notifyByEmail: notifyByEmail,
    notifyByPush: notifyByPush,
  });
};

export const getSystemDetailsForNotifications = async () => {
  return await axios.get(`${API_URL}/v2/groups/service-partner/notification-preference/me`);
};

export const createGroup = async ({ newGroupName, isTopLevelGroup = false }) => {
  return axios.post(
    `${API_URL}/v2/service-partner-groups/create-service-partner-group?newGroupName=${newGroupName}&isTopLevelGroup=${isTopLevelGroup}`,
  );
};

export const createChildGroup = async ({ topLevelGroupId, parentGroupId, newSpGroupName }) => {
  return axios.post(`${API_URL}/v2/service-partner-groups/add-new-service-partner-child-group`, {
    topLevelGroupId,
    parentGroupId,
    newSpGroupName,
  });
};

export const attachChildGroup = async ({ topLevelGroupId, parentGroupId, childGroupId }) => {
  return axios.post(
    `${API_URL}/v2/service-partner-groups/add-child-group?ParentGroupId=${parentGroupId}&ChildGroupId=${childGroupId}&TopLevelGroupId=${topLevelGroupId}`,
  );
};

export const addMultipleChildGroups = async group => {
  // Expected params: { topLevelGroupId: "string", parentGroupId: "string", childGroups : ["string"] }
  return axios.post(`${API_URL}/v2/service-partner-groups/set-child-sysgroups`, group);
};

export const setSettingsToGroups = async (menuId, settingsToGroups) => {
  return await axios.patch(`${API_URL}/v2/service-partner-groups/menu/${menuId}/points/`, settingsToGroups);
};

export const setSchedulingToGroup = async (systemId, groups) => {
  return await axios.post(`${API_URL}/v2/groups/${systemId}/copy-schedule`, groups);
};

export const updateGroupName = async (proAccountId, topLevelGroupId, id, name) => {
  return axios.patch(`${API_URL}/v2/service-partner-groups`, {
    servicePartnerId: proAccountId,
    topLevelGroupId: topLevelGroupId,
    childGroupId: id,
    name: name,
  });
};

export const getModes = async deviceId => {
  return await axios.get(`${API_URL}/v2/devices/${deviceId}/schedule-modes`);
};

export const setModes = async (deviceId, newModes) => {
  return await axios.put(`${API_URL}/v2/devices/${deviceId}/schedule-modes`, newModes);
};

export const deleteMode = async (deviceId, modeId) => {
  return await axios.delete(`${API_URL}/v2/devices/${deviceId}/schedule-modes/${modeId}`);
};

export const getWeeklySchedules = async deviceId => {
  return await axios.get(`${API_URL}/v2/devices/${deviceId}/weekly-schedules`);
};

export const setWeeklySchedules = async (deviceId, weeklySchedules) => {
  return await axios.put(`${API_URL}/v2/devices/${deviceId}/weekly-schedules`, weeklySchedules);
};

export const getScheduleConfig = async deviceId => {
  return await axios.get(`${API_URL}/v2/devices/${deviceId}/schedule-config`);
};

export const setVacationSchedules = async (deviceId, vacationSchedules) => {
  return await axios.put(`${API_URL}/v2/devices/${deviceId}/vacation-schedules`, vacationSchedules);
};

export const getVacationSchedules = async deviceId => {
  return await axios.get(`${API_URL}/v2/devices/${deviceId}/vacation-schedules`);
};

export const getScheduleModeStatus = async deviceId => {
  return await axios.get(`${API_URL}/v2/devices/${deviceId}/schedule-status`);
};

export const setScheduleOverride = async (deviceId, scheduleOverride) => {
  return await axios.put(`${API_URL}/v2/devices/${deviceId}/override-mode`, scheduleOverride);
};

export const getThirdPartyApps = async userId => {
  return await axios.get(`${API_URL}/v2/grants/${userId}/3rd-party`);
};

export const removeThirdPartyAppAccess = async (userId, clientId) => {
  return await axios.delete(`${API_URL}/v2/grants/${userId}/${clientId}`);
};

export const getFaqQuestions = async (brandId, lastUsedLanguage) => {
  return await axios.get(`${API_URL}/v2/faq?brand=${brandId}&locale=${lastUsedLanguage}&tag=pro`);
};

export const getFaqItems = async (brandId, lastUsedLanguage) => {
  return await axios.get(`${API_URL}/v2/faq/all?brand=${brandId}&locale=${lastUsedLanguage}&tag=pro`);
};

export const getPendingProAccountUsers = async () => {
  return await axios.get(`${API_URL}/v3/RegistrationInvites/pending/service-partner`);
};

export const deletePendingProAccountInvite = async inviteId => {
  return await axios.delete(`${API_URL}/v2/RegistrationInvites/service-partner/invites/${inviteId}`);
};

export const getProAccountInviteStatus = inviteId => {
  return axios.get(`${API_URL}/v3/RegistrationInvites/pending/invite/${inviteId}`);
};

export const updatePendingProAccountRole = async (inviteId, role) => {
  return await axios.patch(
    `${API_URL}/v2/RegistrationInvites/pending/service-partner/invites/${inviteId}/role/${role}`,
  );
};

export const resendPendingProAccountInvite = async inviteId => {
  return await axios.put(`${API_URL}/v2/RegistrationInvites/pending/service-partner/invites/${inviteId}/resend`);
};

export const redeemVoucher = async voucherCode => {
  return await axios.post(`${API_URL}/v2/service-partner-vouchers/redeem/${voucherCode}`, CONFIG);
};
