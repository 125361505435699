// TODO: Rename to config
const namespaces = {
  HEADER: 'header',
  DASHBOARD: 'dashboard',
  HISTORY: 'history',
  GENERAL_SECTION: 'general_section',
  PRO_ACCOUNT: 'PRO_ACCOUNT',
  PROFILE_SETTINGS: 'profile_settings',
  SYSTEM_SETTINGS: 'system_settings',
  SCHEDULING: 'scheduling',
};

const makeHeaderEvent = (event = '') => ({
  namespace: namespaces.HEADER,
  event,
});

const makeDashboardEvent = (event = '') => ({
  namespace: namespaces.DASHBOARD,
  event,
});

const makeHistoryEvent = (event = '') => ({
  namespace: namespaces.HISTORY,
  event,
});

const makeGeneralSectionEvent = (event = '') => ({
  namespace: namespaces.GENERAL_SECTION,
  event,
});

const makeProAccountTrackingEvent = (event = '') => ({
  namespace: namespaces.PRO_ACCOUNT,
  event,
});

const makeProfileSettingsTrackingEvent = (event = '') => ({
  namespace: namespaces.PROFILE_SETTINGS,
  event,
});

const makeSystemSettingsTrackingEvent = (event = '') => ({
  namespace: namespaces.SYSTEM_SETTINGS,
  event,
});

const makeSchedulingTrackingEvent = (event = '') => ({
  namespace: namespaces.SCHEDULING,
  event,
});

export const headerTrackingEvents = {
  HEADER_LOGO_CLICK: makeHeaderEvent('header_logo_click'),
  HEADER_LOGOUT: makeHeaderEvent('header_logout'),
  HEADER_SYSTEMS_NAVIGATE: makeHeaderEvent('header_systems_navigate'),
  HEADER_SOFTWARE_NAVIGATE: makeHeaderEvent('header_software_navigate'),
  HEADER_REPORTS_NAVIGATE: makeHeaderEvent('header_reports_navigate'),
  HEADER_SP_SETTINGS_NAVIGATE: makeHeaderEvent('header_sp_settings_navigate'),
  HEADER_REQUEST_ACCESS_NAVIGATE: makeHeaderEvent('header_request_access_navigate'),
  HEADER_STORE_NAVIGATE: makeHeaderEvent('header_store_navigate'),
  HEADER_PROFILE_SETTINGS_NAVIGATE: makeHeaderEvent('header_profile_settings_navigate'),
  HEADER_SMART_HOME_DEVICES_NAVIGATE: makeHeaderEvent('header_smart_home_devices_navigate'),
  HEADER_REDEEM_VOUCHER: makeHeaderEvent('header_redeem_voucher'),
};

export const dashboardTrackingEvents = {
  NOTIFICATION_CARD_LINK_CLICK: makeDashboardEvent('notification_card_link_click'),
  STATUS_CARD_LINK_CLICK: makeDashboardEvent('status_card_link_click'),

  MAP_DRAG: makeDashboardEvent('map_drag'),
  MAP_MARKER_CLICK: makeDashboardEvent('map_marker_click'),

  SEARCH_FOR_SYSTEM: makeDashboardEvent('search_for_system'),

  FILTER_MODAL_OPEN: makeDashboardEvent('filter_modal_open'),
  FILTER_STATUS_SELECT: makeDashboardEvent('filter_status_select'),
  FILTER_TAG_SELECT: makeDashboardEvent('filter_tag_select'),
  FILTER_TAG_DISMISS: makeDashboardEvent('filter_tag_dismiss'),
  FILTER_APPLY_CLICK: makeDashboardEvent('filter_apply_click'),

  LIST_SORT_BY_SYSTEM_NAME: makeDashboardEvent('list_sort_by_system_name'),
  LIST_SORT_BY_ADDRESS: makeDashboardEvent('list_sort_by_address'),
  LIST_SORT_BY_CUSTOMER: makeDashboardEvent('list_sort_by_customer'),
  LIST_SORT_BY_TAG: makeDashboardEvent('list_sort_by_tag'),
  LIST_SORT_BY_STATUS: makeDashboardEvent('list_sort_by_status'),

  LIST_SYSTEM_CLICK: makeDashboardEvent('list_system_click'),
  LIST_GROUP_TOGGLE: makeDashboardEvent('list_group_toggle'),
};

export const historyTrackingEvents = {
  TOGGLE_SECOND_CHART: makeHistoryEvent('toggle_second_chart'),
  SHOW_CHART_CONFIG: makeHistoryEvent('show_chart_config_modal'),
  CHART_CONFIG_PARAM_ADDED: makeHistoryEvent('chart_config_param_added'),
  // controls
  CHART_POINTS_SHOW: makeHistoryEvent('chart_points_show'),
  CHART_ZOOM_OUT: makeHistoryEvent('chart_zoom_out'),
  CHART_ZOOM_IN: makeHistoryEvent('chart_zoom_in'),
  CHART_EXPORT: makeHistoryEvent('chart_export'),
  CHART_EXPAND: makeHistoryEvent('chart_expand'),
  // periods
  CHART_PERIOD_DAY: makeHistoryEvent('chart_period_day'),
  CHART_PERIOD_WEEK: makeHistoryEvent('chart_period_week'),
  CHART_PERIOD_MONTH: makeHistoryEvent('chart_period_month'),
  CHART_PERIOD_YEAR: makeHistoryEvent('chart_period_year'),
  CHART_PERIOD_CUSTOM: makeHistoryEvent('chart_period_custom'),
};

export const generalSectionTrackingEvents = {
  REPORTS_WEEKLY_TAB_CLICK: makeGeneralSectionEvent('reports_weekly_tab_click'),
  REPORTS_DAILY_TAB_CLICK: makeGeneralSectionEvent('reports_daily_tab_click'),
  SOFTWARE_MY_SYSTEMS_DROPDOWN_CLICK: makeGeneralSectionEvent('software_my_systems_dropdown_click'),
  SOFTWARE_RELEASE_NOTES_CLICK: makeGeneralSectionEvent('software_release_notes_click'),
};

export const proAccountTrackingEvents = {
  SETTINGS_PROFILE_TAB_NAVIGATE: makeProAccountTrackingEvent('settings_profile_tab_navigate'),
  SETTINGS_LEAVE_TAB_NAVIGATE: makeProAccountTrackingEvent('settings_leave_tab_navigate'),
  SETTINGS_DELETE_TAB_NAVIGATE: makeProAccountTrackingEvent('settings_delete_tab_navigate'),
  SETTINGS_SECURITY_TAB_NAVIGATE: makeProAccountTrackingEvent('settings_security_tab_navigate'),
  SETTINGS_AGREEMENT_TAB_NAVIGATE: makeProAccountTrackingEvent('settings_agreement_tab_navigate'),
  SECURITY_CONNECTED_USERS_SORT_BY_NAME: makeProAccountTrackingEvent('security_connected_users_sort_by_name'),
  SECURITY_CONNECTED_USERS_SORT_BY_EMAIL: makeProAccountTrackingEvent('security_connected_users_sort_by_email'),
};

export const profileSettingsTrackingEvents = {
  PROFILE_TAB_NAVIGATE: makeProfileSettingsTrackingEvent('profile_tab_navigate'),
  APPEARANCE_TAB_NAVIGATE: makeProfileSettingsTrackingEvent('appearance_tab_navigate'),
  NOTIFICATIONS_TAB_NAVIGATE: makeProfileSettingsTrackingEvent('notifications_tab_navigate'),
  PASSWORD_TAB_NAVIGATE: makeProfileSettingsTrackingEvent('password_tab_navigate'),
  DELETE_TAB_NAVIGATE: makeProfileSettingsTrackingEvent('delete_tab_navigate'),
  NOTIFICATIONS_EMAIL_TAB_NAVIGATE: makeProfileSettingsTrackingEvent('notifications_email_tab_navigate'),
  NOTIFICATIONS_PUSH_TAB_NAVIGATE: makeProfileSettingsTrackingEvent('notifications_push_tab_navigate'),

  NOTIFICATIONS_EMAILS_DAILY_REPORT_TOGGLE_ON: makeProfileSettingsTrackingEvent(
    'notifications_emails_daily_report_toggle_on',
  ),
  NOTIFICATIONS_EMAILS_DAILY_REPORT_TOGGLE_OFF: makeProfileSettingsTrackingEvent(
    'notifications_emails_daily_report_toggle_off',
  ),
  NOTIFICATIONS_EMAILS_WEEKLY_REPORT_TOGGLE_ON: makeProfileSettingsTrackingEvent(
    'notifications_emails_weekly_report_toggle_on',
  ),
  NOTIFICATIONS_EMAILS_WEEKLY_REPORT_TOGGLE_OFF: makeProfileSettingsTrackingEvent(
    'notifications_emails_weekly_report_toggle_off',
  ),
  NOTIFICATIONS_EMAIL_DISABLE_ALL_TOGGLE_ON: makeProfileSettingsTrackingEvent(
    'notifications_email_disable_all_toggle_on',
  ),
  NOTIFICATIONS_EMAIL_DISABLE_ALL_TOGGLE_OFF: makeProfileSettingsTrackingEvent(
    'notifications_email_disable_all_toggle_off',
  ),
  NOTIFICATIONS_PUSH_DISABLE_ALL_TOGGLE_ON: makeProfileSettingsTrackingEvent(
    'notifications_email_disable_all_toggle_on',
  ),
  NOTIFICATIONS_PUSH_DISABLE_ALL_TOGGLE_OFF: makeProfileSettingsTrackingEvent(
    'notifications_email_disable_all_toggle_off',
  ),
};

export const systemSettingsTrackingEvents = {
  STATUS_TAB_NAVIGATE: makeSystemSettingsTrackingEvent('status_tab_navigate'),
  NOTIFICATIONS_TAB_NAVIGATE: makeSystemSettingsTrackingEvent('notifications_tab_navigate'),
  MENU_TAB_NAVIGATE: makeSystemSettingsTrackingEvent('menu_tab_navigate'),
  HISTORY_TAB_NAVIGATE: makeSystemSettingsTrackingEvent('history_tab_navigate'),
  DEVICES_TAB_NAVIGATE: makeSystemSettingsTrackingEvent('devices_tab_navigate'),
  SCHEDULING_TAB_NAVIGATE: makeSystemSettingsTrackingEvent('scheduling_tab_navigate'),
  CUSTOMER_INFO_TAB_NAVIGATE: makeSystemSettingsTrackingEvent('customer_info_tab_navigate'),
  ABOUT_MANUFACTURER_TAB_NAVIGATE: makeSystemSettingsTrackingEvent('about_manufacturer_tab_navigate'),
  SYSTEM_DIAGRAM_TAB_NAVIGATE: makeSystemSettingsTrackingEvent('system_diagram_tab_navigate'),

  STATUS_TAB_ADD_TAG: makeSystemSettingsTrackingEvent('status_tab_add_tag'),
  NOTIFICATIONS_TAB_INBOX_NAVIGATE: makeSystemSettingsTrackingEvent('notifications_tab_inbox_navigate'),
  NOTIFICATIONS_TAB_ARCHIVED_NAVIGATE: makeSystemSettingsTrackingEvent('notifications_tab_archived_navigate'),
  NOTIFICATIONS_TAB_ACTIVATE_AID_MODE: makeSystemSettingsTrackingEvent('notifications_tab_activate_aid_mode'),
  NOTIFICATIONS_TAB_RESET_ALARM: makeSystemSettingsTrackingEvent('notifications_tab_reset_alarm'),

  DEVICES_TAB_UPDATE_AVAILABLE_CLICK: makeSystemSettingsTrackingEvent('devices_tab_update_available_click'),
  DEVICES_TAB_UPDATE_BUTTON_CLICK: makeSystemSettingsTrackingEvent('devices_tab_update_button_click'),
  DEVICES_TAB_RELEASE_NOTES_CLICK: makeSystemSettingsTrackingEvent('devices_tab_release_notes_click'),
  DEVICES_TAB_DOWNLOAD_TO_COMPUTER_CLICK: makeSystemSettingsTrackingEvent('devices_tab_download_to_computer_click'),
};

export const schedulingTrackingEvents = {
  SCHEDULE_TAB_NAVIGATE: makeSchedulingTrackingEvent('schedule_tab_navigate'),
  VACATION_TAB_NAVIGATE: makeSchedulingTrackingEvent('vacation_tab_navigate'),

  SCHEDULE_SEND_TO_GROUP_CLICK: makeSchedulingTrackingEvent('schedule_send_to_group_click'),
  SCHEDULE_COPY_CLICK: makeSchedulingTrackingEvent('schedule_copy_click'),
  SCHEDULE_ADD_CLICK: makeSchedulingTrackingEvent('schedule_add_click'),
  SCHEDULE_CHANGE_WEEK_CLICK: makeSchedulingTrackingEvent('schedule_change_week_click'),

  EDIT_MODES_ADD_CLICK: makeSchedulingTrackingEvent('edit_modes_add_click'),
  EDIT_MODES_EDIT_CLICK: makeSchedulingTrackingEvent('edit_modes_edit_click'),
  EDIT_MODES_RENAME_CLICK: makeSchedulingTrackingEvent('edit_modes_rename_click'),
  EDIT_MODES_DELETE_CLICK: makeSchedulingTrackingEvent('edit_modes_delete_click'),

  EDIT_EVENT_NEXT_CLICK: makeSchedulingTrackingEvent('edit_event_next_click'),
  EDIT_EVENT_DISABLE_CLICK: makeSchedulingTrackingEvent('edit_event_disable_click'),
  EDIT_EVENT_DELETE_CLICK: makeSchedulingTrackingEvent('edit_event_delete_click'),

  VACATION_ADD_CLICK: makeSchedulingTrackingEvent('vacation_add_click'),
};
