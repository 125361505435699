import _ from 'lodash';

import * as api from '@/api';
import * as azureB2CApi from '@/api/azureB2C';
import {
  deleteAuthTokens,
  deleteTempTokens,
  getTempTokens,
  isAzureB2CAuth,
  isPersist,
  putAuthTokens,
  putTempTokens,
  setAzureB2CAuth,
  setAzureB2CPolicyName,
} from '@/utils/localStorage/authentication';

import { SET_PRO_ACCOUNT, getUserInfo } from '@/components/AppInitializer/actions';
import { AUTH_REQUEST_HEADER_SCOPE_DEFAULT, OFFLINE_ACCESS } from '@/config/common';
import events, { eventEmitter } from '@/events';

import { clearDashAlertStatus } from '@/utils/localStorage/alerts';
import { removeFirstChart, removeSecondChart } from '@/utils/localStorage/history';
import { removeLastUsedSystemId } from '@/utils/localStorage/lastUsedSystem';
import { splitVatNumber } from '../EditServicePartner/utils';

export const ISSUE_TOKEN_REQUEST = 'login/AUTH_REQUEST';
export const ISSUE_TOKEN_RESPONSE_USER = 'login/AUTH_RESPONSE_USER';
export const ISSUE_TOKEN_RESPONSE_SP = 'login/AUTH_RESPONSE_SP';
export const LOGIN_FAILED = 'login/LOGIN_FAILED';

export const login = (username, password) => async dispatch => {
  if (await issueToken(username, password)(dispatch)) {
    await getUserInfo()(dispatch);
    return true;
  }
  return false;
};

export const SET_USER_LOGGED_IN = 'login/SET_USER_LOGGED_IN';
export const setUserLoggedInAction = () => dispatch => {
  dispatch({ type: SET_USER_LOGGED_IN });
};

export const loginAsServicePartner =
  ({ servicePartner, userCredentials }) =>
  async dispatch => {
    if (isAzureB2CAuth()) {
      try {
        await api.authorizeUserUnderProAccount(servicePartner.servicePartnerId);
        await getUserInfo()(dispatch);
        dispatch({ type: SET_USER_LOGGED_IN });
        return true;
      } catch {
        return false;
      }
    }
    if (await issueTokenSP({ servicePartner, userCredentials })(dispatch)) {
      await getUserInfo()(dispatch);
      return true;
    }
    return false;
  };

export const issueTokenSP =
  ({ servicePartner, userCredentials }) =>
  async dispatch => {
    dispatch({ type: ISSUE_TOKEN_REQUEST });

    try {
      const { refresh_token } = getTempTokens() || {};
      const isRememberMeChecked = isPersist();

      const { email, password } = userCredentials || {};
      const { servicePartnerId } = servicePartner || {};

      const response = isRememberMeChecked
        ? await api.reissueAuthToken(refresh_token, servicePartnerId)
        : await api.issueAuthTokenProAccount({
            username: email,
            password,
            spId: servicePartnerId,
            options: { scope: AUTH_REQUEST_HEADER_SCOPE_DEFAULT },
          });

      if (response.status === 200) {
        if (isRememberMeChecked) {
          // Need to keep temp token for not checked option for Creating SP in issueTokenWithCreatedSp
          deleteTempTokens();
        }

        putAuthTokens(response.data);

        dispatch({ type: ISSUE_TOKEN_RESPONSE_SP });
        return true;
      }
      return false;
    } catch (e) {
      if (e.response && e.response.status === 400 && e.response.data.error === 'invalid_grant') {
        dispatch({ type: LOGIN_FAILED, error: e.response.data.error_description });
        return false;
      }
    }

    dispatch({ type: LOGIN_FAILED, error: 'unknown' });
    return false;
  };

export const reissueTokenSP = async servicePartnerId => {
  const { refresh_token = '' } = getTempTokens() || {};
  try {
    const response = await api.reissueAuthToken(refresh_token, servicePartnerId);
    if (response?.status === 200) {
      putAuthTokens(response.data);
    }
  } catch (error) {
    const { response = {} } = error || {};
    const { status = '', data = {} } = response;
    if (status === 400 && data?.error === 'invalid_grant') {
      throw new Error('Error occurred while reissuing token.');
    }
  }
};

export const loginAsConsumer = userCredentials => async dispatch => {
  const isTokenIssuedSuccessfully = await issueConsumerToken(userCredentials)(dispatch);
  if (!isTokenIssuedSuccessfully) {
    return false;
  }

  await getUserInfo()(dispatch);
  return true;
};

export const issueConsumerToken =
  (userCredentials = {}) =>
  async dispatch => {
    dispatch({ type: ISSUE_TOKEN_REQUEST });

    try {
      const isRememberMeChecked = isPersist();

      const { email: username, password } = userCredentials;
      const scope = `${AUTH_REQUEST_HEADER_SCOPE_DEFAULT}${isRememberMeChecked ? ` ${OFFLINE_ACCESS}` : ''}`;

      const response = await api.issueAuthToken({
        username,
        password,
        options: { scope },
      });

      if (response.status === 200) {
        if (isRememberMeChecked) {
          // Need to keep temp token for not checked option for Creating SP in issueTokenWithCreatedSp
          deleteTempTokens();
        }

        putAuthTokens(response.data);
        dispatch({ type: ISSUE_TOKEN_RESPONSE_SP });

        return true;
      }

      return false;
    } catch (e) {
      if (e.response && e.response.status === 400 && e.response.data.error === 'invalid_grant') {
        dispatch({ type: LOGIN_FAILED, error: e.response.data.error_description });
        return false;
      }
    }

    dispatch({ type: LOGIN_FAILED, error: 'unknown' });
    return false;
  };

export const loginWithAzureB2CAction = code => async dispatch => {
  dispatch({ type: ISSUE_TOKEN_REQUEST });

  try {
    const response = await azureB2CApi.issueAzureB2CTokens(code, azureB2CApi.AZURE_B2C_POLICY_LOGIN);
    if (response.status === 200) {
      setAzureB2CAuth();
      putAuthTokens(response.data);
      setAzureB2CPolicyName(azureB2CApi.AZURE_B2C_POLICY_LOGIN);
      const res = await api.getActiveProAccountIds();

      if (res.status === 200) {
        const organizations = _.sortBy(res.data, 'servicePartnerName');
        dispatch({ type: ISSUE_TOKEN_RESPONSE_USER, organizations: organizations });

        if (res.data.length === 0) {
          await getUserInfo()(dispatch);
          setUserLoggedInAction()(dispatch);
        }
        return res.data;
      }

      return [];
    }
  } catch (e) {
    if (e.response && e.response.status === 400 && e.response.data.error === 'invalid_grant') {
      dispatch({ type: LOGIN_FAILED, error: e.response.data.description });
      return null;
    }
  }

  dispatch({ type: LOGIN_FAILED, error: 'unknown' });
  return false;
};

export const issueToken = (username, password) => async dispatch => {
  dispatch({ type: ISSUE_TOKEN_REQUEST });

  try {
    const response = await api.issueAuthToken({ username, password });
    if (response.status === 200) {
      putTempTokens(response.data);
      const token = response.data.access_token;
      const res = await api.getActiveProAccountIds(token);

      if (res.status === 200) {
        const organizations = _.sortBy(res.data, 'servicePartnerName');
        dispatch({ type: ISSUE_TOKEN_RESPONSE_USER, organizations: organizations });

        if (res.data.length === 0) {
          putAuthTokens(response.data);

          await getUserInfo()(dispatch);
          setUserLoggedInAction()(dispatch);
        }
        return res.data;
      }
      return [];
    }
  } catch (e) {
    if (e.response && e.response.status === 400 && e.response.data.error === 'invalid_grant') {
      dispatch({ type: LOGIN_FAILED, error: e.response.data.error_description });
      return null;
    }
  }

  dispatch({ type: LOGIN_FAILED, error: 'unknown' });
  return false;
};

export const LOGOUT_USER = 'login/LOGOUT_USER';
export const logoutUser = (allowRedirect = true) => {
  const isAzureB2C = isAzureB2CAuth();

  eventEmitter.emit(events.inApp.auth.LOGOUT);

  deleteAuthTokens();

  removeLastUsedSystemId();
  clearDashAlertStatus();

  // TODO: Setup history.clearStoredCharts()
  removeFirstChart();
  removeSecondChart();

  if (isAzureB2C) {
    window.location.href = azureB2CApi.getAzureB2CLogoutLink();
    setAzureB2CPolicyName('');

    return { type: 'LOGOUT_USER_WITH_AZURE' };
  }

  return { type: LOGOUT_USER, allowRedirect };
};

export const SET_UP_AGREEMENTS_REQUEST = 'login/SET_UP_AGREEMENTS_REQUEST';
export const SET_UP_AGREEMENTS_RESPONSE_SUCCEEDED = 'login/SET_UP_AGREEMENTS_RESPONSE_SUCCEEDED';
export const SET_UP_AGREEMENTS_RESPONSE_FAILED = 'login/SET_UP_AGREEMENTS_RESPONSE_FAILED';
export const setUpUserAgreements =
  (username, password, acceptedTosVersion, acceptedPrivacyPolicyVersion) => async dispatch => {
    dispatch({ type: SET_UP_AGREEMENTS_REQUEST });
    try {
      await api.setUpAgreements(username, password, acceptedTosVersion, acceptedPrivacyPolicyVersion);
      dispatch({ type: SET_UP_AGREEMENTS_RESPONSE_SUCCEEDED });
      return true;
    } catch (e) {
      if (e.response && e.response.status === 400 && e.response.data.error === 'invalid_grant') {
        dispatch({ type: SET_UP_AGREEMENTS_RESPONSE_FAILED, error: e.response.data.description });
        return false;
      }
    }

    dispatch({ type: SET_UP_AGREEMENTS_RESPONSE_FAILED, error: 'unknown' });
    return false;
  };

const tryParse = str => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
};

export const UPDATE_PRO_ACCOUNT_AGREEMENTS_REQUEST = 'editServicePartner/UPDATE_PRO_ACCOUNT_AGREEMENTS_REQUEST';
export const UPDATE_PRO_ACCOUNT_AGREEMENTS_ERROR = 'editServicePartner/UPDATE_PRO_ACCOUNT_AGREEMENTS_ERROR';
export const updateServicePartnerAgreements = (servicePartnerId, agreements) => async dispatch => {
  dispatch({ type: UPDATE_PRO_ACCOUNT_AGREEMENTS_REQUEST });
  try {
    const request = {
      organizationId: servicePartnerId,
      toSAcceptedMyUptech: agreements.tosAcceptedMyUptech,
    };
    const patchResponse = await api.editProAccountInfo(request);

    if (patchResponse.status === 204) {
      const getResponse = await api.getProAccountInfo(servicePartnerId);

      if (getResponse.status === 200) {
        const servicePartner = splitVatNumber(getResponse.data);
        dispatch({ type: SET_PRO_ACCOUNT, servicePartner });
      } else {
        dispatch({ type: UPDATE_PRO_ACCOUNT_AGREEMENTS_ERROR });
      }
      return;
    }
    dispatch({ type: UPDATE_PRO_ACCOUNT_AGREEMENTS_ERROR });
  } catch (e) {
    dispatch({ type: UPDATE_PRO_ACCOUNT_AGREEMENTS_ERROR });
  }
};

export const VALIDATE_CREDENTIALS_REQUEST = 'login/VALIDATE_CREDENTIALS_REQUEST';
export const VALIDATE_CREDENTIALS_RESPONSE_SUCCEEDED = 'login/VALIDATE_CREDENTIALS_RESPONSE_SUCCEEDED';
export const VALIDATE_CREDENTIALS_RESPONSE_FAILED = 'login/VALIDATE_CREDENTIALS_RESPONSE_FAILED';
export const validateUserCredentials = (username, password) => async dispatch => {
  dispatch({ type: VALIDATE_CREDENTIALS_REQUEST });
  try {
    const response = await api.validateUserCredentials(username, password);
    if (response.data.isEmailConfirmed) {
      dispatch({ type: VALIDATE_CREDENTIALS_RESPONSE_SUCCEEDED, data: response.data });
    } else {
      dispatch({ type: LOGIN_FAILED, error: 'email_not_confirmed' });
    }
    return { isOk: true, userId: response.data.id, isEmailConfirmed: response.data.isEmailConfirmed };
  } catch (e) {
    if (e.response && e.response.status === 400) {
      const error = tryParse(e.response.data.description) || e.response.data.description;
      dispatch({ type: VALIDATE_CREDENTIALS_RESPONSE_FAILED, error });
      return { isOk: false, error };
    }
  }

  dispatch({ type: VALIDATE_CREDENTIALS_RESPONSE_FAILED, error: 'unknown' });
  return { isOk: false };
};

export const VALIDATE_OLD_USER_CREDENTIALS_REQUEST = 'login/VALIDATE_OLD_USER_CREDENTIALS_REQUEST';
export const VALIDATE_OLD_USER_CREDENTIALS_RESPONSE_SUCCEEDED =
  'login/VALIDATE_OLD_USER_CREDENTIALS_RESPONSE_SUCCEEDED';
export const validateOldUserCredentials = (username, password) => async dispatch => {
  if (await checkUserEmail(username)) {
    dispatch({ type: LOGIN_FAILED, error: 'invalid_username_or_password' });
    return;
  }
  dispatch({ type: VALIDATE_OLD_USER_CREDENTIALS_REQUEST });
  try {
    const response = await api.validateOldUserCredentials(username, password);
    dispatch({ type: VALIDATE_OLD_USER_CREDENTIALS_RESPONSE_SUCCEEDED, data: response.data });
    return true;
  } catch (e) {
    if (e.response && e.response.status === 404) {
      dispatch({ type: LOGIN_FAILED, error: 'invalid_username_or_password' });
      return false;
    }
  }

  dispatch({ type: LOGIN_FAILED, error: 'unknown' });
  return false;
};

export const MIGRATE_OLD_USER_REQUEST = 'login/MIGRATE_OLD_USER_REQUEST';
export const MIGRATE_OLD_USER_RESPONSE_SUCCEEDED = 'login/MIGRATE_OLD_USER_RESPONSE_SUCCEEDED';
export const MIGRATE_OLD_USER_RESPONSE_FAILED = 'login/MIGRATE_OLD_USER_RESPONSE_FAILED';
export const migrateOldUser = (oldUserId, acceptedTosVersion, acceptedPrivacyPolicyVersion) => async dispatch => {
  dispatch({ type: MIGRATE_OLD_USER_REQUEST });
  try {
    await api.migrateOldUser(oldUserId, acceptedTosVersion, acceptedPrivacyPolicyVersion, null);
    dispatch({ type: MIGRATE_OLD_USER_RESPONSE_SUCCEEDED });
    return true;
  } catch (e) {
    if (e.response && e.response.status === 400) {
      dispatch({ type: MIGRATE_OLD_USER_RESPONSE_FAILED, error: e.response.data.description });
      return false;
    }
  }

  dispatch({ type: MIGRATE_OLD_USER_RESPONSE_FAILED, error: 'unknown' });
  return false;
};

const checkUserEmail = async email => {
  const {
    data: { access_token },
  } = await api.issueAnonymousAuthToken();
  try {
    await api.findUserByEmail(access_token, email);
    return true;
  } catch (e) {
    return false;
  }
};
