// TODO: Rename file to config.ts
import { headerTrackingEvents } from '@/components/tracking/Clarity';
import { ROLES, SP_ROLES } from '@/config/user';

import profileIcon from '@/assets/img/profile.svg';

const showIotStore = process.env.IOT_STORE_ENABLED === 'true';
const runningEnv = process.env.ENV;

const IsValidRoles = (spRoles, requiredRoles) => spRoles.some(role => requiredRoles.includes(role));

// TODO: Use brand names from constants when defined
const voucherEligibleBrands = ['NIBE', runningEnv === 'production' ? '' : 'CLIMATEMASTER'].filter(Boolean);

const ALL_ROLES = Object.values(ROLES);
const ALL_SP_ROLES = Object.values(SP_ROLES);

// TODO: Setup file with all links and use it for menu and route paths
export const menuItemIds = {
  Systems: 'menu.items.systems',
  Software: 'menu.items.software',
  Reports: 'menu.items.reports',
  ServicePartnerSettings: 'menu.items.service-partner-settings',
  RequestAccess: 'menu.items.request.access',
  Store: 'menu.items.store',
  SmartHomeDevices: 'menu.items.iot-store',
  ProfileSettings: 'profile-settings.title',
  LogOut: 'menu.items.logout',
  General: 'menu.headers.general',
  ServicePartner: 'menu.headers.pro-account',
  Language: 'menu.headers.language',
  Profile: 'menu.headers.profile',
  RedeemVouchers: 'menu.items.redeem-voucher',
};

export const menuItemIdToEventName = {
  [menuItemIds.Systems]: headerTrackingEvents.HEADER_SYSTEMS_NAVIGATE,
  [menuItemIds.Software]: headerTrackingEvents.HEADER_SOFTWARE_NAVIGATE,
  [menuItemIds.Reports]: headerTrackingEvents.HEADER_REPORTS_NAVIGATE,
  [menuItemIds.ServicePartnerSettings]: headerTrackingEvents.HEADER_SP_SETTINGS_NAVIGATE,
  [menuItemIds.RequestAccess]: headerTrackingEvents.HEADER_REQUEST_ACCESS_NAVIGATE,
  [menuItemIds.Store]: headerTrackingEvents.HEADER_STORE_NAVIGATE,
  [menuItemIds.ProfileSettings]: headerTrackingEvents.HEADER_PROFILE_SETTINGS_NAVIGATE,
  [menuItemIds.RedeemVouchers]: headerTrackingEvents.HEADER_REDEEM_VOUCHER,
};

export const generalMenu = [
  {
    name: 'Systems',
    id: menuItemIds.Systems,
    path: () => '/systems',
    requiredRoles: ALL_SP_ROLES,
    subscriptionDepend: true,
    visible: ({ userPermissions: { spRoles } }, requiredRoles) => IsValidRoles(spRoles, requiredRoles),
  },
  {
    name: 'Software',
    id: menuItemIds.Software,
    path: () => '/update-firmware',
    requiredRoles: ALL_SP_ROLES,
    visible: () => true,
  },
  {
    name: 'Reports',
    id: menuItemIds.Reports,
    path: () => '/reports',
    requiredRoles: ALL_SP_ROLES,
    subscriptionDepend: true,
    visible: () => true,
  },
];

export const servicePartnerMenu = [
  {
    name: 'Service Partner',
    id: menuItemIds.ServicePartnerSettings,
    path: () => '/service-partner-settings',
    requiredRoles: ALL_SP_ROLES,
    visible: ({ userPermissions: { spRoles } }, requiredRoles) => IsValidRoles(spRoles, requiredRoles),
  },
  {
    name: 'Request Access',
    id: menuItemIds.RequestAccess,
    path: () => '/request-access',
    requiredRoles: [ROLES.admin, ROLES.manager],
    subscriptionDepend: true,
    brandDepend: true,
    visible: ({ userPermissions: { spRoles } }, requiredRoles) => IsValidRoles(spRoles, requiredRoles),
  },
  {
    name: 'Store',
    id: menuItemIds.Store,
    path: () => '/store',
    brandDepend: true,
    requiredRoles: [ROLES.admin, ROLES.buyer],
    visible: ({ userPermissions: { spRoles } }, requiredRoles) => IsValidRoles(spRoles, requiredRoles),
  },
  {
    name: 'Smart Home Devices',
    id: menuItemIds.SmartHomeDevices,
    path: () => '/',
    brandDepend: true,
    requiredRoles: [ROLES.admin, ROLES.buyer],
    visible: ({ userPermissions: { spRoles }, showStore, isServicePartnerCountrySweden }, requiredRoles) =>
      showIotStore && showStore && isServicePartnerCountrySweden && IsValidRoles(spRoles, requiredRoles),
  },
  {
    name: 'Redeem Voucher',
    id: menuItemIds.RedeemVouchers,
    path: () => '/redeem-voucher',
    requiredRoles: [ROLES.admin, ROLES.buyer],
    visible: ({ userPermissions: { spRoles }, brands }, requiredRoles) =>
      brands.some(b => voucherEligibleBrands.includes(b.brandId)) && IsValidRoles(spRoles, requiredRoles),
    subscriptionDepend: true,
    brandDepend: true,
  },
];

export const profileMenu = [
  {
    name: 'Profile settings',
    id: menuItemIds.ProfileSettings,
    path: () => '/profile-settings',
    requiredRoles: ALL_SP_ROLES,
    visible: () => true,
  },
  {
    name: 'Log out',
    id: menuItemIds.LogOut,
    path: () => '/logout',
    requiredRoles: ALL_SP_ROLES,
    visible: () => true,
  },
];

export const mainMenu = [
  {
    name: 'General',
    id: menuItemIds.General,
    align: 'left',
    useIcon: false,
    icon: '',
    children: generalMenu,
  },
  {
    name: 'Service Partner',
    id: menuItemIds.ServicePartner,
    align: 'left',
    useIcon: false,
    icon: '',
    children: servicePartnerMenu,
  },
  {
    name: 'Language',
    id: menuItemIds.Language,
    align: 'right',
    children: [],
    visible: () => true,
  },
  {
    name: 'Profile',
    id: menuItemIds.Profile,
    align: 'right',
    useIcon: true,
    icon: profileIcon,
    children: profileMenu,
  },
];

export const guestMainMenu = [
  {
    name: 'Language',
    id: menuItemIds.Language,
    align: 'right',
    children: [],
    visible: () => true,
  },
  {
    name: 'Profile',
    id: menuItemIds.Profile,
    align: 'right',
    useIcon: true,
    icon: profileIcon,
    children: [
      {
        name: 'Profile settings',
        id: menuItemIds.ProfileSettings,
        path: () => '/profile-settings',
        requiredRoles: ALL_ROLES,
        visible: () => true,
      },
      {
        name: 'Log out',
        id: menuItemIds.LogOut,
        path: () => '/logout',
        requiredRoles: ALL_ROLES,
        visible: () => true,
      },
    ],
  },
];
