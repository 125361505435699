import { DEFAULT_BRAND } from '@/config/brands';
import { theme as defaultTheme } from 'jpi-cloud-web-ui-kit';

const theme = {
  ...defaultTheme,
  name: DEFAULT_BRAND,
  COLORS: {
    ...defaultTheme.COLORS,
    warning: '#EEAB46', // '#FF5722'
    info: '#1A237E',
    text: {
      ...defaultTheme.COLORS.text,
      muted: '#585858',
      primaryContrast: '#ffffff',
    },
    background: {
      ...defaultTheme.COLORS.background,
      table: '#f7f7f7',
    },
    border: {
      main: '#ddd',
    },
    shadow: {
      light: '#0000001A',
      medium: '#0000004D',
    },
    chart: {
      default: '#585858',
      success: ' #4bb945',
      error: ' #a81524',
      warning: ' #eeab46',
      info: ' #4689ee',
      fade: {
        success: '#75B871',
        error: '#C25B66',
        warning: '#F3C47E',
        info: '#7EACF3',
      },
    },

    defaultBrand: '#1A237E',
  },
};

export default theme;
